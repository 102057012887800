.login-screen {
    max-width: 800px;
    margin: auto;
}

.login-screen h1 {
    margin-top: 7%;
    font-size: 50pt;
    padding: 5px;
}

.login-screen h2 {
    font-size: 20pt;
    margin-left: 5px;
    padding: 5px;
    margin-top: -10px;
}

.login-screen form {
    margin: 40px auto auto;
    padding: 25px 50px;
    max-width: 75%;
    border: 4px dashed #ddd;
    border-radius: 5px;
}

.login-screen input {
    font-weight: bold;
    text-align: center;
}

.app {
    height: 100vh;
    width: 100vw;
    overflow: auto;
    background-color: #fdfdfd;
}

.login-background {
    background: linear-gradient(-20deg, rgba(255, 255, 255, 1) 50%, rgba(220, 220, 220, 0.1) 100%);
}

.login-info {
    position: absolute;
    top: 0;
    right: 10px;
}

.login-info > span {
    vertical-align: middle;
}

.pseudobutton:hover {
    cursor: pointer;
    text-decoration: none;
}

.project-row {
    font-weight: bold;
    font-size: 14pt;
    cursor: pointer;
}

.report-table {
    max-width: 750px;
}

.report-table td {
    vertical-align: middle;
}

table.table-fit {
    width: auto !important;
    table-layout: auto !important;
}
table.table-fit thead th, table.table-fit tfoot th {
    width: auto !important;
}
table.table-fit tbody td, table.table-fit tfoot td {
    width: auto !important;
}

.project-table-container, .report-table-container {
    position: relative;
    margin: 20px;
}

#dummy-div-wrapper {
    overflow-x: scroll;
    display: none;
}

#dummy-div {
    height: 20px;
}

.project-table td {
    vertical-align: middle;
    white-space: nowrap;
    padding-right: 10px;
    padding-left: 10px;
    background-clip: padding-box;
    border-right: 1px solid #dee2e6;
}

.project-table td:last-of-type {
    border-right: none;
}

.project-table td:first-of-type {
    padding-left: 2.3em;
    min-width: 500px;
    white-space: normal;
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: #fdfdfd;
}

.personal-report-table td:first-of-type, .personal-report-table th:first-of-type {
    padding-left: 1.2em;
    min-width: 500px;
    white-space: normal;
    position: sticky;
    left: 0;
    z-index: 1;
    outline: 2px solid #dee2e6;
    border: none;
    outline-offset: -1px;
    background-color: #fdfdfd;
}

.personal-report-table th:first-of-type {
    outline: 1px solid transparent;
}

.personal-report-table td:first-of-type strong {
    margin-left: -0.4em;
}

.personal-report-table tr:nth-of-type(2n+1) td {
    background-color: #efefef;
}

.personal-report-table tr:nth-of-type(2n) td {
    background-color: #fdfdfd;
}

.project-table tbody tr:hover {
    background-color: rgba(180, 180, 180, 0.6);
}

.personal-report-table tbody tr:hover td {
    background-color: rgb(200, 200, 200);
}

.project-table tbody tr:hover td:first-of-type {
    background-color: rgb(209, 209, 209);
}

.project-table .project-row td:first-of-type {
    padding-left: 0.7em;
}

.issue-time-cell .add-time {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: white;
    background-color: rgba(0.7, 0.7, 0.7, 0.4);
}

.issue-time-cell .add-time * {
    position: relative;
    top: calc(50% - 13px);
}

.issue-time-cell:hover .add-time {
    display: unset;
}

.issue-time-cell {
    position: relative;
    text-align: center;
    cursor: pointer;
}

.time-sum-cell {
    text-align: center;
    cursor: auto;
}

.dashboard h1 {
    margin: 10px 20px;
}

.modal {
    display: flex;
    background-color: rgba(.5, .5, .5, .5);
    justify-content: center;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

.modal > .modal-content {
    background-color: white;
    position: absolute;
    top: 10%;
    max-height: 80%;
    max-width: 80%;
    width: unset;
    padding: 10px 20px;
    overflow: auto;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}

.modal .modal-title {
    padding-top: 5px;
    padding-bottom: 10px;
}

.modal .modal-controls button {
    margin-left: 5px;
    margin-bottom: 5px;
}

button > svg, .btn > svg {
    margin-right: 5px;
}

button.icon-button > svg {
    margin-right: unset;
}

.no-break {
    white-space: nowrap;
}

.checkbox-small-mr .form-check {
    margin-right: .3rem !important;
}

.inline-form-group {
    display: inline-block;
}

.inline-form-group {
    vertical-align: middle;
    margin-right: 0.7em;
}

.inline-form-group > label {
    margin-right: 0.4em;
}

.date-input {
    width: 7em;
    text-align: center;
}

form .date-input {
    width: 8em;
}

.project-list {
    margin-bottom: 20px;
}

.project-list thead tr:first-of-type td {
    font-weight: bold;
    border-top: none;
}

.dashboard {
    position: relative;
}

.dashboard .header > div {
    margin-top: 25px;
}

.project-table thead td,
.personal-report-table th,
.personal-report-table .sum-row {
    color: #666;
    cursor: auto;
}

.personal-report-table th:first-of-type, .personal-report-table tr td:first-of-type {
    border-left: none;
}
.personal-report-table th:last-of-type, .personal-report-table tr td:last-of-type {
    border-right: none;
}

.personal-report-table th {
    text-align: center;
    border-top: none;
}

.personal-report-table td.no-break {
    white-space: nowrap;
}

.project-row .issue-time-cell,
.project-row .time-sum-cell {
    font-size: 12pt;
}

.mt-10 {
    margin-top: 10px;
}

.mt-15 {
    margin-top: 15px
}

button + button {
    margin-left: 5px;
}

.dashboard .header {
    margin-left: 25px;
}

#issues-project,
#new-item-project,
#new-item-issue,
.react-datepicker-popper {
    z-index: 5000 !important;
}

.issue-list ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.issue-list li {
    display: block;
    text-align: left;
}

.issue-list svg {
    margin-right: 5px;
}

.issue-iid {
    font-weight: bold;
    color: #444 !important;
    display: inline-block;
    width: 4em;
}

.issues-modal .modal-content {
    width: 50%;
}

.time-input {
    display: inline-block;
    max-width: 4.5em;
    margin-right: 0.25em;
}

.time-input:not(:first-of-type) {
    margin-left: 0.7em;
}

.modal-form-inline-container {
    margin-bottom: 10px;
    margin-left: 5px;
}

.dashboard-filter > div:first-of-type {
    width: 25rem;
    display: inline-block;
    margin-right: 1rem;
    position: relative;
}

.dashboard-filter > div:first-of-type .clear-button {
    position: absolute;
    top: .3em;
    right: .6em;
    font-size: 110%;
    color: #7f7f7f;
    cursor: pointer;
}

.dashboard-filter .form-group {
    display: inline-block;
}

.dashboard-filter .checkbox-small-mr .form-check {
    margin-right: 0.1rem !important;
}

#new-item-comment {
    height: 4em;
}

.loader {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
}

.dialog-close-button {
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
    font-size: 115%;
}

.no-add {
    cursor: auto;
}

.gitlab-issue-link {
    color: #212529;
}

.gitlab-issue-link:hover {
    text-decoration: none;
    color: #212529;
}

.loader .pseudolink {
    color: white;
    text-decoration: underline;
    cursor: pointer;
}

.weekend {
    background-color: rgba(150, 210, 255, 0.37) !important;
}

.today {
    background-color: rgba(80, 255, 60, 0.35) !important;
}

.loading-bar {
    width: 100%;
    background-color: #ffc107;
    font-size: 8pt;
    padding: 1px;
}

.loading-bar span {
    margin-left: 20px;
}

.loading-bar svg {
    margin-right: 3px;
    animation: spin 3s linear infinite;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}